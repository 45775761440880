<script setup lang="ts">
import type { SearchResult } from '~/composables/masto/search'

defineProps<{
  result: SearchResult
  active: boolean
}>()

function onActivate() {
  (document.activeElement as HTMLElement).blur()
}
</script>

<template>
  <RouterLink
    class="search-link no-decoration"
    hover:bg-active
    :active="active"
    :to="result.to"
    :aria-selected="active"
    @click="() => onActivate()">
    <SearchHashtagInfo v-if="result.type === 'hashtag'" :hashtag="result.data" />
    <SearchAccountInfo v-else-if="result.type === 'account'" :account="result.data" />
    <SearchStatusInfo v-else-if="result.type === 'status'" :status="result.data" />
  </RouterLink>
</template>

<style lang="scss">
.search-link {
  padding: var(--padding-small);
}
</style>
