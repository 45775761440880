import type { MaybeRefOrGetter } from '@vueuse/core'
import type { RouteLocation } from 'vue-router'
import type { mastodon } from '#types'
import type { DefaultPaginationParams, SearchParams, SearchType } from '~/types'

export type UseSearchOptions = MaybeRefOrGetter<Partial<Omit<SearchParams, keyof DefaultPaginationParams | 'q'>>>

export interface BuildSearchResult<K extends keyof any, T> {
  id: string
  type: K
  data: T
  to: RouteLocation & {
    href: string
  }
}
export type AccountSearchResult = BuildSearchResult<'account', mastodon.v1.Account>
export type HashTagSearchResult = BuildSearchResult<'hashtag', mastodon.v1.Tag>
export type StatusSearchResult = BuildSearchResult<'status', mastodon.v2.StatusSearchResult>

export type SearchResult = HashTagSearchResult | AccountSearchResult | StatusSearchResult

export function useSearch(query: MaybeRefOrGetter<string>, options: UseSearchOptions = {}) {
  const loading = ref(false)
  const accounts = ref<AccountSearchResult[]>([])
  const hashtags = ref<HashTagSearchResult[]>([])
  const statuses = ref<StatusSearchResult[]>([])

  const appendResults = (results: mastodon.v2.Search, empty = false) => {
    if (empty) {
      accounts.value = []
      hashtags.value = []
      statuses.value = []
    }
    accounts.value = [
      ...accounts.value,
      ...results.accounts.map<AccountSearchResult>(account => ({
        type: 'account',
        id: account.id,
        data: account,
        to: getAccountRoute(account),
      })),
    ]
    hashtags.value = [
      ...hashtags.value,
      ...results.hashtags.map<HashTagSearchResult>(hashtag => ({
        type: 'hashtag',
        id: `hashtag-${hashtag.name}`,
        data: hashtag,
        to: getTagRoute(hashtag.name),
      })),
    ]
    statuses.value = [
      ...statuses.value,
      ...results.statuses.map<StatusSearchResult>(status => ({
        type: 'status',
        id: status.id,
        data: status,
        to: getStatusRoute(status),
      })),
    ]
  }

  const q = () => resolveUnref(query).trim()

  watch(q, (q) => {
    loading.value = !!(q && isHydrated.value)
  })

  debouncedWatch(q, async (q) => {
    if (!q || !isHydrated.value) {
      return
    }

    let type: SearchType | undefined
    if (q.startsWith('@')) {
      type = 'accounts'
    }
    else if (q.startsWith('/')) {
      return
    }
    else if (q.startsWith('#')) {
      type = 'hashtags'
    }
    if (type !== undefined && q.length === 1) {
      return
    }

    loading.value = true
    const results = await $fetch<mastodon.v2.Search>('/api/search', {
      query: {
        ...resolveUnref(options),
        resolve: useAuth().loggedIn.value,
        q,
        type,
      },
    })
    appendResults(results, true)
    loading.value = false
  }, { debounce: 300 })

  return {
    accounts,
    hashtags,
    statuses,
    loading: readonly(loading),
  }
}
