<template>
  <header class="header-main">
    <slot name="header-start" />
    <UiBar padding bg class="search-wrap">
      <SearchWidget />
    </UiBar>
    <span class="header-end">
      <slot name="header-end" />
    </span>
  </header>
</template>

<style lang='scss'>
.header-main {
  --color-panel-ui: color-mix(in oklch, var(--color-bg) 90%, transparent);
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  min-height: calc(var(--base-size));
  width: 100cqi;
  z-index: 1000;
  padding-top: var(--padding-mini);
  margin-bottom: var(--size-half);

  .search-wrap {
    margin-inline: auto;
  }

  h1, h2, h3, h4 {
    margin: 0;
  }
  .header-end {
    margin-left: auto;
  }
}
</style>
