<script setup lang="ts">
import type { SearchResult } from '@/composables/masto/search'
import type { MenuItem } from '~/types'
interface UiMenuListInstance {
  onKeyDown: (event: KeyboardEvent) => void
}
const router = useRouter()

const query = ref('')
const popupRef = ref()
const el = ref<HTMLElement>()
const input = ref<HTMLInputElement>()
const menuList = ref<UiMenuListInstance>()
const { focused } = useFocusWithin(el)

const { accounts, hashtags, statuses, loading } = useSearch(query)

const results = computed<MenuItem<SearchResult>[]>(() => {
  if (query.value.length === 0) {
    return []
  }
  return [
    ...hashtags.value.slice(0, 3).map(item => ({ label: '', meta: item })),
    ...accounts.value.map((item, i) => ({ label: '', meta: item, separator: i === 0 && hashtags.value.length })),
    ...statuses.value.map((item, i) => ({ label: '', meta: item, separator: i === 0 && (hashtags.value.length || accounts.value.length) }))
  ]
})

// Reset index when results change
watch([results, focused, loading], () => {
  popupRef.value?.toggle(loading.value || results.value.length > 0)
  popupRef.value?.updatePosition()
})

function activate(result?: SearchResult) {
  if (!result) { return }
  (document.activeElement as HTMLElement).blur()
  if (input.value) { input.value.value = '' }
  router.push(result.to)
}

function onKeyDown (event: KeyboardEvent) {
  menuList.value?.onKeyDown(event)
}
</script>

<template>
  <div ref="el">
    <div>
      <InputBox
        ref="input"
        v-model="query"
        :placeholder="isHydrated ? 'search' : ''"
        @keydown="onKeyDown">
        <template v-if="query.trim().length" #add-on>
          <button class="compact" @click="query = ''; input?.focus()">
            <span class="icon-close small" />
          </button>
        </template>
      </InputBox>
    </div>
    <SearchPopup ref="popupRef">
      <template v-if="!loading">
        <UiMenuList
          v-if="results.length > 0"
          ref="menuList"
          :items="results"
          :on-select-item="activate"
          class="search-results">
          <template #item="{item, active}">
            <SearchResult
              v-if="item.meta"
              :key="item.meta.id"
              :active="active"
              :result="item.meta"
              :tabindex="focused ? 0 : -1"
              :class="[item.meta?.type, { separator: item.separator }]" />
          </template>
        </UiMenuList>
        <span v-else>
          {{ 'search_empty' }}
        </span>
      </template>
      <template v-else>
        <SpinnerIcon />
      </template>
    </SearchPopup>
  </div>
</template>

<style lang="scss">
.search-results {
  max-height: min(var(--base-size) * 15, 100svh - var(--base-size) * 2);
  overflow-y: auto;
}
</style>
