<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { Props } from 'tippy.js'

const props = withDefaults(defineProps<{
  placement?: Props['placement']
  arrow?: Props['arrow']
  theme?: Props['theme']
}>(),
{
  placement: 'bottom-start',
  arrow: false,
  theme: 'indi'
})

const emit = defineEmits(['state'])

const tippy = ref<typeof Tippy>()
const triggerButton = ref<HTMLElement>()
const durationMs = ref(100)

function getBody () {
  return document?.body
}

function focusButton () {
  triggerButton.value?.focus()
}

const show = computed(() => tippy.value?.show)
const close = computed(() => tippy.value?.hide)

const toggle = (val: boolean) => {
  if (val) { return show.value() }
  if (val) { return close.value() }
}

const updatePosition = () => {
  tippy.value?.tippy.popperInstance?.update()
}

defineExpose({ show, close, toggle, updatePosition })
</script>

<template>
  <Tippy
    ref="tippy"
    class="tippy-popup"
    interactive
    allow-html
    :theme="props.theme"
    :arrow="props.arrow"
    :hide-on-click="true"
    :append-to="getBody"
    :offset="[0, 8]"
    :duration="durationMs"
    trigger="none"
    :placement="props.placement"
    :on-hide="focusButton"
    @state="emit('state', $event)">
    <template #content="{ state, hide }">
      <UiCollapsibleBox :open="state.isVisible" :t="`${durationMs}ms`">
        <slot :close="hide" />
      </UiCollapsibleBox>
    </template>
  </Tippy>
</template>
